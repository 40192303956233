<template>
  <div>
    <vue-editor
      class='editFormFieldVueEditor'
      v-model='inputValue'>
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
export default {
  name: 'EditFormFieldVueEditor',
  props: {
    metaData: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  components: {
    VueEditor,
  },
  data () {
    return {
      inputValue: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.inputValue = newVal
        }
      }, 
      immediate: true,
    },
    inputValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:inputValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
  },
}
</script>
